import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomePageComponent} from './home-page.component';
import {BulkmailSubscriptionComponent} from './bulkmail-subscription/bulkmail-subscription.component';
import {ImageHeaderComponent} from './image-header/image-header.component';
import {CardCarouselComponent} from './card-carousel/card-carousel.component';
import {TrainingVideosComponent} from './training-videos/training-videos.component';
import {PageNotFoundComponent} from '../page-not-found/page-not-found.component';
import {OtaButtonModule} from "@cvpp-ota/input";
import {IndividualVideoComponent} from './training-videos/individual-videos/individual-video.component';
import {CardCarouselModule} from './card-carousel/card-carousel.module';

@NgModule({
  declarations: [
    HomePageComponent,
    BulkmailSubscriptionComponent,
    ImageHeaderComponent,
    CardCarouselComponent,
    TrainingVideosComponent,
    PageNotFoundComponent,
    IndividualVideoComponent
  ],
  imports: [
    CommonModule,
    OtaButtonModule,
    CardCarouselModule
  ]
})
export class HomePageModule {
}
