import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CardShortcutsComponent} from './card-shortcuts/card-shortcuts.component';
import {CarouselModule} from 'primeng/carousel';
import {OtaButtonModule} from '@cvpp-ota/input';

@NgModule({
  declarations: [
    CardShortcutsComponent
  ],
  imports: [
    CommonModule,
    CarouselModule,
    OtaButtonModule
  ],
  exports: [
    CardShortcutsComponent
  ]
})
export class CardCarouselModule {
}
