import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomePageModule} from "./home-page/home-page.module";
import {OtaFooterModule, OtaMenuModule, OtaNavigationModule} from "@cvpp-ota/menu";
import {OtaSecurityConfigModule, OtaSecurityModule} from '@cvpp-ota/security';
import {environment} from '../environments/environment';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ApsErrorComponent} from './aps-error/aps-error.component';


@NgModule({
  declarations: [
    AppComponent,
    ApsErrorComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HomePageModule,
    OtaNavigationModule,
    OtaFooterModule,
    OtaMenuModule.forRoot(
      {
        apsRules: environment.apsRules,
        baseUrls: environment.baseUrls,
        homePageUrl: environment.homePageUrl,
        profileMenuOptions: environment.profileMenuOptions
      }
    ),
    OtaSecurityModule,
    OtaSecurityConfigModule.forRoot(
      {
        fordUserAuthUrl: environment.fordUserAuthUrl,
        applicationIds: environment.applicationIds,
        apsRules: environment.apsRules,
        protocol: environment.protocol,
        authRequest: {
          oauthGrantType: environment.oauthGrantType,
          oauthClientId: environment.apsOAuthClientId,
          oauthClientSecret: environment.apsOAuthClientSecret,
          oauthScope: environment.apsOAuthScope,
          oauthProviderUrl: environment.apsOAuthProviderUrl,
          oauthResource: environment.oauthResource
        },
        login: {
          ssoServiceUrl: environment.oauth.ssoServiceUrl,
          ssoClientId: environment.oauth.ssoClientId,
          ssoResourceId: environment.oauth.ssoResourceId,
          ssoRedirectUri: environment.oauth.ssoRedirectUri,
          ssoResponseType: environment.oauth.ssoResponseType,
          ssoEnabled: true
        },
        apsPepUrl: environment.apsPepUrl
      }
    ),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
