import {Component, OnInit} from '@angular/core';
import {CardHotLink} from "./model/card-hot-link";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-card-carousel',
  templateUrl: './card-carousel.component.html',
  styleUrls: ['./card-carousel.component.scss']
})
export class CardCarouselComponent implements OnInit {

  private cards: Array<CardHotLink> = [];

  constructor() {
  }

  ngOnInit() {
    environment.hotLinks.forEach(card => this.cards.push(card));
  }
}
