import {Component} from '@angular/core';

@Component({
  selector: 'app-bulkmail-subscription',
  templateUrl: './bulkmail-subscription.component.html',
  styleUrls: ['./bulkmail-subscription.component.scss']
})
export class BulkmailSubscriptionComponent {

  constructor() {
  }

  subscribeClick() {
  }
}
